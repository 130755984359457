import React from 'react'
import './footer.css'
import logo from '../../assets/logo1.png'
import { HashLink } from 'react-router-hash-link';
import { AiFillFacebook, AiFillInstagram, AiFillLinkedin} from "react-icons/ai";
import { Link } from 'react-router-dom';
import tweet from '../../assets/twitter(1).png'
const Footer = () => {
    const date = new Date();
  return (
    <div className='footer'>
      <div className='footer-container'>
        <div className='footer-top'>
            <div className='footer-image'>
                <img src={logo} alt='company-logo'/>
            </div>
            <div className='footer-company'>
                <h1>Company</h1>
                <ul>
                    <li> 
                        <HashLink className='link-cta' to='/#header' ><span>Home</span></HashLink>
                    </li>
                    <li>
                        <HashLink className='link-cta' to='/about-page#aboutss'><span>About us</span></HashLink>
                    </li>
                    <li>
                        <HashLink className='link-cta' to='/ace#ace'><span> ACE Management </span></HashLink>
                    </li>
                    <li>
                        <HashLink className='link-cta' to='/bod#aboutss'><span>Board of Directors</span></HashLink>
                    </li>

                    <li>
                        <HashLink className='link-cta' to='/mgt#aboutss'><span>Management Team</span></HashLink>
                    </li>
                    <li>
                        <HashLink className='link-cta' to='/contact#contacttts'><span>Contact</span></HashLink>
                     </li>
                </ul>
            </div>
            <div className='footer-company'>
                <h1>Products & Services</h1>
                <ul>
                    <li>
                        <HashLink className='link-cta' to='/bank#bank'><span style={{color:'white'}}>Rayyan Banking</span></HashLink>
                    </li>
                    <li>
                        <HashLink className='link-cta' to='/investment#investment'><span style={{color:'white'}}>Rayyan Investment</span></HashLink>
                    </li>
                    <li>
                        <HashLink className='link-cta' to='/finance#finance'><span style={{color:'white'}}>Rayyan Finance</span></HashLink>
                    </li>
                    <li>
                        <HashLink className='link-cta' to='/wakala#wakala'><span style={{color:'white'}}>Wakala and Kafala</span></HashLink>
                    </li>
                </ul>
            </div>
            <div className='footer-info'>
            <h1>Location</h1>
                <p>+234-9049712650</p>
                <p>info@rayyanmfb.com</p>
                <p>Plot 1, Beside Bama Motor Park, <br/> Bama Road, Maiduguri</p>
            </div>
        </div>

        <div className='footer-bottom'>
            <p>@ {date.getFullYear()} Rayyan Bank</p>
            <div className='social-icon'>
                    <Link to='https://www.facebook.com/profile.php?id=61550051434651&is_tour_dismissed=true' style={{textDecoration: 'none', width: 0 }}><AiFillFacebook  className='footer-icon'/> </Link> 
                    <Link to='https://twitter.com/rayyanbank' style={{textDecoration: 'none', width: 0 }}> <img src={tweet}  className='footer-icon'/> </Link> 
                    <Link to='https://www.instagram.com/rayyanbank.ng/' style={{textDecoration: 'none', width: 0 }}> <AiFillInstagram  className='footer-icon'/> </Link>
                    <Link to='https://www.linkedin.com/in/rayyan-bank-090115282/' style={{textDecoration: 'none', width: 0 }}> <AiFillLinkedin  className='footer-icon'/> </Link>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
