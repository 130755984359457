import React, {useState} from 'react'
import Navbar from '../../components/navbar/Navbar'
import banner from '../../assets/s-b.jpg'
import './servicesPage.css'
import { Link } from 'react-router-dom'
import image1 from '../../assets/wakala.jpg'
import image2 from '../../assets/im-7.jpg'
import image3 from '../../assets/Wandk.jpg'
import { HashLink } from 'react-router-hash-link'
import caret1 from '../../assets/caret-right.svg'
import Footer from '../../components/footer/Footer'
import { Modal, Button} from 'react-bootstrap';

const Wakala = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onButtonClick = () => {
        // using Java Script method to get PDF file
        fetch('corporate.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'corporate.pdf';
                alink.click();
            })
        })
        setShow(false)
    }

    const onButtonClick2 = () => {
        // using Java Script method to get PDF file
        fetch('individual.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'individual.pdf';
                alink.click();
            })
            
        })
        setShow(false)
    }
  return (
    <div className='BankingServices'>
        <Navbar />
      <div className='b-s-container'>
        <div className='about-header'>
            <div className='b-header-s-container'>
                <div className='about-image' >
                <div className='about-opacity'></div>
                    <img id='wakala' src={banner} />
                </div>
                <div className='b-s-title' data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                    <h1>Wakala & Kafala</h1>
                    <p>Rayyan Bank's Wakala and Kafala Services provide individuals and businesses with opportunities for effective fund management, investment diversification, and mutually beneficial partnerships.</p>
                </div>
            </div>
        
        </div>
        <div className='b-s-open-account'>
            <h1>New Account Application </h1>
            <p>We are grateful for the chance to offer you our banking services and eagerly anticipate assisting you in meeting your financial needs.</p>
            <div className='b-s-open-account-link'>
                <button onClick={handleShow} style={{textDecoration: 'none', color:'white', background:'transparent', outline:'none', border:'none'}}> OPEN AN ACCOUNT ONLINE </button>
            </div>
        </div>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header >
          <Modal.Title style={{fontSize:'19px', fontWeight:'700'}}>Open a new account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='acct-modal'>
                <p style={{fontWeight:'500'}}>Click the button below to download our individual/corporate account opening forms. </p>
                    <div style={{display:'flex', flexDirection:'column'}}>
                    <div>
                        <button onClick={onButtonClick2} style={{textDecoration: 'none', color:'white', background:'#56B870', outline:'none', border:'none',width:'180px'}}> Individual Account </button>
                    </div>
                    <div>
                        <button onClick={onButtonClick} style={{textDecoration: 'none', color:'white', background:'#56B870', outline:'none', border:'none', marginTop:'1rem', width:'180px' }}> Corporate Account </button>
                    </div>
                    <div>
                       <Link to="https://ibank.rayyanbank.com.ng/" target="_blank" style={{textDecoration: 'none'}}> <div style={{display: 'flex', justifyContent: "center", alignItems: "center", color:'white', background:'#56B870', outline:'none', border:'none', marginTop:'1rem', width:'180px' , fontWeight:'normal'}}> Internet Banking </div> </Link>
                    </div>
                </div>  
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
        <div className='b-s-bank-acconts-container'>
            <div className='b-s-bank-acconts1'>
                <div className='b-s-personalBanking'>
                    <h1 style={{color: '#56B870'}}>Wakala Services</h1>
                    <p>Wakala refers to a contractual agreement in which one party acts as an agent (the Wakeel) for another party (the principal) to manage and invest their funds. At Rayyan Bank, our Wakala Services enable individuals and businesses to entrust their funds to our experienced and knowledgeable investment professionals. As the Wakeel, we deploy the funds in Shariah-compliant investment opportunities, aiming to generate competitive returns while ensuring ethical investment practices. Our Wakala Services offer transparency, accountability, and the potential for attractive profit-sharing arrangements.</p>
                   
                </div>
                <div className='b-s-personal-images'>
                    <img src={image1} />
                </div>
            </div>
            <div style={{  backgroundColor: 'white', flexDirection:'row-reverse' }} className='b-s-bank-acconts1'>
                <div className='b-s-personalBanking'>
                    <h1 style={{color: '#56B870'}}>Kafala Services</h1>
                    <p>Kafala Services are designed to facilitate the growth and development of businesses through cooperative partnerships. Kafala refers to a guarantee or sponsorship agreement, where a stronger party provides support to a weaker party. At Rayyan Bank, our Kafala Services aim to provide financial support, mentoring, and advisory services to businesses, particularly small and medium-sized enterprises (SMEs). Through Kafala, we partner with businesses, sharing risks and rewards, and working collaboratively to foster their growth and success. This mutually beneficial relationship promotes entrepreneurship, economic empowerment, and job creation, in line with Islamic principles.</p>
                </div>
                <div className='b-s-personal-images1'>
                    <img src={image2} />
                </div>
                
            </div>
        </div>
        <div className='wakala-content-container'>
            <div style={{backgroundColor:'#f3f3f3'}} className='wakala-content'>
                <h1>Rayyan Bank's Wakala and Kafala Services provide individuals and businesses with opportunities for effective fund management, investment diversification, and mutually beneficial partnerships. We are dedicated to delivering exceptional service, maintaining strict compliance with Shariah principles, and fostering economic growth and prosperity for our clients. Choose Rayyan Bank for reliable and ethical Wakala and Kafala services tailored to your financial aspirations and business goals.</h1>
            </div>
            <div style={{backgroundColor:'white'}} className='b-s-bank-acconts'>
                <div className='b-s-personalBanking'>
                    <h1 style={{color: '#56B870'}}>Our Wakala and Kafala Services offers:</h1>
                    <p>• Expert Guidance: Rayyan Bank's team of experienced professionals provides expert guidance and advice to ensure that funds are invested or business partnerships are established in accordance with Islamic principles and best practices.</p>
                    <p>• Transparency and Accountability: We maintain a high level of transparency in our Wakala and Kafala Services, keeping our clients informed about the investment or partnership activities and providing regular reports on performance and progress.</p>
                    <p>• Risk Management: Our team conducts comprehensive due diligence and risk assessments to minimize the risks associated with investments and partnerships, striving to ensure the stability and security of funds and business collaborations.</p>
                    <p>• Tailored Solutions: We understand that each client's needs and goals are unique. Our Wakala and Kafala Services are flexible and customizable to meet the specific requirements of individuals and businesses, ensuring that the services align with their financial objectives.</p>
                   
                </div>
                <div className='wakala-images'>
                    <img src={image3} />
                </div>
            </div>

        </div>
        <div className='b-s-bank-acconts-cta' style={{display:'flex', justifyContent:'space-between', alignItems:'center' , background:'#f3f3f3'}}>
            <h1> Related Links </h1>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
            <img src={caret1} />
                <HashLink to='/investment#investment'  style={{color:'#56B870', textDecoration: 'none'}}>Rayyan investment</HashLink>
            </div>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
            <img src={caret1} />
                <HashLink to='/finance#finance' style={{color:'#56B870', textDecoration: 'none'}}>Rayyan financing</HashLink>
            </div>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
            <img src={caret1} />
                <HashLink to='/bank#bank' style={{color:'#56B870', textDecoration: 'none'}}>Banking Services</HashLink>
            </div>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
            <img src={caret1} />
                <HashLink to='/contact#contacttts'  style={{color:'#56B870', textDecoration: 'none'}}>Contact</HashLink>
            </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Wakala
