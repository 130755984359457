import React, {useEffect} from 'react'
import './header.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slider2 from '../../constants/sliders/Slider';

const Header = () => { 
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div className='header'>
      <Slider2 />
    </div>
  )
}

export default Header
