import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/logo1.png'
import './navbar.css'
import {  AiFillFacebook, AiFillInstagram, AiFillLinkedin} from "react-icons/ai";
import {RiCloseLine, RiMenu3Line} from "react-icons/ri"
import { HashLink } from 'react-router-hash-link';
import tweet from '../../assets/twitter(1).png'
import tweetNav from '../../assets/twitter2.png'
import nifi from '../../assets/LOGO.png'


const Menu = () => ( 
    <>
    <li>
            <Link className='link-cta' to='/' ><span>Home</span></Link>
        </li>
        <li>
            <a className='link-cta' to='/about'><span>About Us</span></a>
            <ul className='dropdown slide-bottom'>
            <li>
            <Link className='link-cta' to='/about-page'><span style={{color:'white'}}>About Us</span></Link>
            </li>
            <li>
            <Link className='link-cta' to='/ace'><span style={{color:'white'}}>ACE Management</span></Link>
            </li>
            <li>
            <Link className='link-cta' to='/bod'><span style={{color:'white'}}>Board of Directors</span></Link>
           </li>
            <li>
            <Link className='link-cta' to='/mgt'><span style={{color:'white'}}>Management Team</span></Link>
            </li>
            
                </ul>
        </li>
        <li>
            <a className='link-cta' to='/services'><span>Products & Services</span></a>
                <ul className='dropdown slide-bottom'>
            <li>
            <Link className='link-cta' to='/bank'><span style={{color:'white'}}>Banking Services</span></Link>
            </li>
            <li>
            <Link className='link-cta' to='/investment'><span style={{color:'white'}}>Rayyan Investment</span></Link>
           </li>
            <li>
                <Link className='link-cta' to='/finance'><span style={{color:'white'}}>Rayyan Financing</span></Link>
            </li>
            <li>
                <Link className='link-cta' to='/wakala'><span style={{color:'white'}}>Wakala & Kafala</span></Link>
            </li>
                </ul>
        </li>
        <li>
            <Link className='link-cta' to='/contact'><span>Contact</span></Link>
        </li>
        <li>
            <Link className='link-cta' to="https://ibank.rayyanbank.com.ng/" target="_blank" ><span>Internet Banking</span></Link>
        </li>
    </>
)

const Navbar = () => { 
    const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className='navbar'>
        <div className='navbar-container'>
            <div className='navbar-logo'>
                <Link to='/'><img src={logo} alt='company-logo' /></Link>
            </div>
            <div className='navbar-links'>
                <ul>
                   <Menu />
                </ul>

            </div>
            <div className='social-icon'>
                {/* <Link to='https://www.facebook.com/profile.php?id=61550051434651&is_tour_dismissed=true' style={{textDecoration: 'none', width: 0 }}><AiFillFacebook  className='footer-icon'/> </Link> 
                  <Link to='https://twitter.com/rayyanbank' style={{textDecoration: 'none', width: 0 }}> <img src={tweet}  className='footer-icon' width={15} height={15}/> </Link> 
                   <Link to='https://www.instagram.com/rayyanbank.ng/' style={{textDecoration: 'none', width: 0 }}> <AiFillInstagram  className='footer-icon'/> </Link>
                   <Link to='https://www.linkedin.com/in/rayyan-bank-090115282/' style={{textDecoration: 'none', width: 0 }}> <AiFillLinkedin  className='footer-icon'/> </Link> */}
            <div className='nifi'>
                <img src={nifi} alt='nifi-logo' />
            </div>
            </div>

            <div className='navbar-menu-mini '>
        {
            toggleMenu ?
            <RiCloseLine color='#fff' size={30} onClick={()=>setToggleMenu(false)}/>
            : 
            <RiMenu3Line color='#fff' size={30} onClick={()=>setToggleMenu(true)}/>
        }
        {
            toggleMenu && (
                <div className="navbar-menu_container " >
                    <div className={`navbar-menu_links ${ toggleMenu ? "slide-bottom" : "slide-top"}`}>
                        <nav >
                          <ul>
                          <li>
            <HashLink className='link-cta' to='/#header' ><span>Home</span></HashLink>
        </li>
        <li>
            <HashLink className='link-cta' to='/about-page#aboutss'><span>About Us</span></HashLink>
        </li>
        <li>
            <HashLink className='link-cta' to='/bod#aboutss'><span>Board of Directors</span></HashLink>
        </li>
        <li>
            <HashLink className='link-cta' to='/mgt#aboutss'><span>Management Team</span></HashLink>
            </li>
            <li>
            <HashLink className='link-cta' to='/ace#ace'><span> ACE Management</span></HashLink>
            </li>
            <li>
            <HashLink className='link-cta' to='/bank#bank'><span>Rayyan Banking</span></HashLink>
            </li>
            <li>
            <HashLink className='link-cta' to='/investment#investment'><span>Rayyan Investment</span></HashLink>
           </li>
            <li>
                <HashLink className='link-cta' to='/finance#finance'><span>Rayyan Financing</span></HashLink>
            </li>
            <li>
                <HashLink className='link-cta' to='/wakala#wakala'><span>Wakala & Kafala</span></HashLink>
            </li>
            <li>
                <HashLink className='link-cta' to='/contact#contacttts'><span>Contact</span></HashLink>
            </li>
            <li>
                <Link className='link-cta' to="https://ibank.rayyanbank.com.ng/" target="_blank" ><span>Internet Banking</span></Link>
            </li>
                            <div className='social2-icon'>
                                <Link to='https://www.facebook.com/profile.php?id=61550051434651&is_tour_dismissed=true' style={{textDecoration: 'none', width: 0 }}><AiFillFacebook  className='footer-icon'/> </Link> 
                                <Link to='https://twitter.com/rayyanbank' style={{textDecoration: 'none', width: 0 }}> <img src={tweetNav}  className='footer-icon' width={17} height={17}/> </Link>
                                <Link to='https://www.instagram.com/rayyanbank.ng/' style={{textDecoration: 'none', width: 0 }}> <AiFillInstagram  className='footer-icon'/> </Link>
                                <Link to='https://www.linkedin.com/in/rayyan-bank-090115282/' style={{textDecoration: 'none', width: 0 }}> <AiFillLinkedin  className='footer-icon'/> </Link>
                            </div>
                          </ul>
                        </nav>
                      </div>
                    </div>
            )
        }
                
            </div>
        </div>

    </div>
  )
}

export default Navbar
