import React,{useEffect} from 'react'
import './review.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';

const Review = () => {
    useEffect(() => {
        AOS.init();
      }, [])
  return (
    <div className='review'>
        <div className='review-container'>
            <div className='reviews-content'>
                <div className='about-us-title'></div>
                <h3>Sharia Compliant Islamic Bank for Everyone</h3>
            </div>
            <div className='review-text'>
            <p>Rayyan Bank is your go-to Islamic Bank that offers you <span style={{color:'rgb(24,137,119)'}}>Interest-free</span> financing. With a commitment to Sharia-compliant practices, we offer a comprehensive range of banking solutions designed to meet the diverse needs of individuals and businesses. </p> 
            </div>
            <div className='cards-container' data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                <div className='reviews'>
                        <h1> Banking Services </h1>
                        <p>We provide a range of Mudarabah Accounts tailored to meet your personal and business financial needs.</p>
                        <Link className='button-link' to='/bank#bank'>Learn More</Link>
                </div>
                <div className='reviews'>
                    <h1> Rayyan Investment </h1>
                    <p>We provide a range of Mudarabah Accounts tailored to meet your personal and business financial needs.</p>
                    <Link className='button-link' to='/investment#investment'>Learn More</Link>
                </div>
                <div className='reviews'>
                    <h1> Rayyan Financing </h1>
                    <p>We provide a range of Mudarabah Accounts tailored to meet your personal and business financial needs.</p>
                    <Link className='button-link' to='/finance#finance'>Learn More</Link>
                </div>
            </div>
        </div>
      
    </div>
  )
}

export default Review
