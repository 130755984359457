import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import image3 from '../../assets/in.jpeg'
import './slider.css'
import image2 from '../../assets/fin1.jpeg'
import image1 from '../../assets/new-img2.jpg'
const Slider2 = () => {
    const settings = {
          dots: true,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 500,
          autoplay: true,
          autoplaySpeed: 7000,
          pauseOnHover: true,
          cssEase: "linear"
        };
        return (
          <div className="slide"> 
            <Slider {...settings} >
              <div className='slider-container'>
                <div className="slider-image">
                    <div className='background-opacity1'></div>
                    <img src={image3} />
                </div>
                    <div className='slider-text' data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                        <h1>Empowering Lives, Interest-Free</h1>
                        <p>Our commitment to interest-free banking drives us to offer Shariah-compliant financial products and services through our extended digital platforms.</p>
                        <div  state={{backgroundColor:'#137177'}} className='header-btn-div'>
                    <Link className='header-button' to = '/contact'>Get In Touch</Link>
                    </div>
                
                </div>
            </div>
            <div className='slider-container'>
                <div className="slider-image">
                    <div className='background-opacity1'></div>
                    <img src={image2} />
                </div>
                    <div className='slider-text' data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                        <h1>Your Success Story Starts Here</h1>
                        <p>Embark on a journey of financial empowerment with Rayyan bank. We're here to guide you every step of the way.</p>
                        <div  state={{backgroundColor:'#137177'}} className='header-btn-div'>
                    <Link className='header-button' to = '/contact'>Get In Touch</Link>
                    </div>
                
                </div>
            </div>
            <div className='slider-container'>
                <div className="slider-image">
                    <div className='background-opacity1'></div>
                    <img src={image1} />
                </div>
                    <div className='slider-text' data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                        <h1>Turning Ambitions into Realities</h1>
                        <p>Discover the power of financial inclusion with Rayyan bank. Your dreams are our priority.</p>
                        <div  state={{backgroundColor:'#137177'}} className='header-btn-div'>
                    <Link className='header-button' to = '/contact'>Get In Touch</Link>
                    </div>
                </div>
            </div>
            </Slider>
          </div>
        );
}

export default Slider2
