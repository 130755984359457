import React from 'react'
import './home.css'
import Footer from '../../components/footer/Footer'
import Review from '../../components/review/Review'
import Stats from '../../components/stats/Stats'
import Services from '../../components/services/Services'
import Header from '../../components/header/Header'
import Navbar from '../../components/navbar/Navbar'
import AboutRayyan from '../../components/about-rayyan/AboutRayyan'
import Accounts from '../../components/account/Accounts'

const Home = () => {
  return (
    <div className='home'>
      <Navbar />
      <Header />
      <Review />
      <AboutRayyan />
      <Accounts />
      <Footer />
    </div>
  )
}

export default Home
