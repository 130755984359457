import React,{useEffect, useState} from 'react'
import { accountData} from '../../constants'
import './accounts.css'
import Slider from "react-slick";

const Accounts = () => {
    const [slidesToShow, setSlidesToShow] = useState(3);

    useEffect(() => {
    const handleResize = () => {
        if (window.innerWidth >= 1024) {
          setSlidesToShow(3); // Large screen
        } else if (window.innerWidth >= 768) {
          setSlidesToShow(2); // Medium screen
        } else {
          setSlidesToShow(1); // Small screen
        }
      };
  
      // Initial setup
      handleResize();
  
      // Listen for window resize events
      window.addEventListener('resize', handleResize);
  
      // Clean up the event listener
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);


    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        cssEase: "linear"
      };
  return (
    <div className='accounts'>
    <div></div>
        <div className='acounts-content'>
            <Slider {...settings} className='accounts-content-container-div'>
                {
                    accountData.map((item, index)=>{
                        return(
                            <div key={item.id} className='accounts-content-container'>
                                <div className='accounts-content-image'>
                                    <img src={item.img} />
                                </div>
                                <div className='accounts-content-text'>
                                    <h1>{item.title}</h1>
                                    <p>{item.content}</p>
                                </div>
                            </div> 
                        )
                    })
                } 
            </Slider>
            
        </div>
      
    </div>
  )
}


export default Accounts
