import React,{useState} from 'react'
import Navbar from '../../components/navbar/Navbar'
import banner from '../../assets/s-b.jpg'
import './servicesPage.css'
import { Modal, Button} from 'react-bootstrap';
import image1 from '../../assets/im-8.jpg'
import image2 from '../../assets/im-5.jpg'
import image3 from '../../assets/sme.jpg'
import image4 from '../../assets/lease.jpg'
import image5 from '../../assets/innovation.jpg'

import { HashLink } from 'react-router-hash-link'
import caret1 from '../../assets/caret-right.svg'
import Footer from '../../components/footer/Footer'

import { Link } from 'react-router-dom';


const Financing = () => { 
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onButtonClick = () => {
        // using Java Script method to get PDF file
        fetch('corporate.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'corporate.pdf';
                alink.click();
            })
        })
        setShow(false)
    }

    const onButtonClick2 = () => {
        // using Java Script method to get PDF file
        fetch('individual.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'individual.pdf';
                alink.click();
            })
            
        })
        setShow(false)
    }
  return (
    <div className='BankingServices'>
        <Navbar />
      <div id='finance' className='b-s-container'>
        <div className='about-header'>
            <div className='b-header-s-container'>
                <div className='about-image' >
                <div className='about-opacity'></div>
                    <img id='aboutss' src={banner} />
                </div>
                <div className='b-s-title' data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                    <h1>Rayyan Financing</h1>
                    <p>Our financing solutions provide access to funds while adhering to Islamic principles, ensuring transparency, fairness, and ethical practices. </p>
                </div>
            </div>
        
        </div>
        <div className='b-s-open-account'>
            <h1>New Account Application </h1>
            <p>We are grateful for the chance to offer you our banking services and eagerly anticipate assisting you in meeting your financial needs.</p>
            <div className='b-s-open-account-link'>
                <button onClick={handleShow} style={{textDecoration: 'none', color:'white', background:'transparent', outline:'none', border:'none'}}> OPEN AN ACCOUNT ONLINE </button>
            </div>
        </div>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header >
          <Modal.Title style={{fontSize:'19px', fontWeight:'700'}}>Open a new account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='acct-modal'>
                <p style={{fontWeight:'500'}}>Click the button below to download our individual/corporate account opening forms. </p>
                    <div style={{display:'flex', flexDirection:'column'}}>
                    <div>
                        <button onClick={onButtonClick2} style={{textDecoration: 'none', color:'white', background:'#56B870', outline:'none', border:'none',width:'180px'}}> Individual Account </button>
                    </div>
                    <div>
                        <button onClick={onButtonClick} style={{textDecoration: 'none', color:'white', background:'#56B870', outline:'none', border:'none', marginTop:'1rem', width:'180px' }}> Corporate Account </button>
                    </div>
                    <div>
                       <Link to="https://ibank.rayyanbank.com.ng/" target="_blank" style={{textDecoration: 'none'}}> <div style={{display: 'flex', justifyContent: "center", alignItems: "center", color:'white', background:'#56B870', outline:'none', border:'none', marginTop:'1rem', width:'180px' , fontWeight:'normal'}}> Internet Banking </div> </Link>
                    </div>
                </div>  
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
        <div className='b-s-bank-acconts-container'>
            <div className='b-s-bank-acconts1'>
                <div className='b-s-personalBanking'>
                    <h1 style={{color: '#56B870'}} >Murabaha Asset Financing</h1>
                    <p>Rayyan Bank's Murabaha Asset Financing provides a Shariah-compliant solution for individuals and businesses looking to acquire fixed assets such as machinery, equipment, vehicles, or real estate. Under this financing arrangement, the bank purchases the desired asset on behalf of the customer and then enter a rent-to-own arrangement, which includes the cost of the asset plus a profit margin. The customer repays the amount over a specified period, making it an affordable and structured financing option.</p>
                </div>
                <div className='b-s-personal-images'>
                    <img src={image1} />
                </div>
            </div>
            <div style={{  backgroundColor: 'white', flexDirection:'row-reverse'}} className='b-s-bank-acconts1'>
                <div className='b-s-personalBanking'>
                    <h1 style={{color: '#56B870'}}>Murabaha Inventory Financing</h1>
                    <p>For businesses looking to finance their inventory or stock purchases, Rayyan Bank offers Murabaha Stock Financing. This financing facility allows businesses to acquire the necessary goods or merchandise by utilizing a cost-plus-profit arrangement. The bank purchases the stock on behalf of the customer and sells it back at an agreed-upon price, allowing the customer to repay the amount over a defined period.</p>
                </div>
                <div className='b-s-personal-images1'>
                    <img src={image2} />
                </div>
                
            </div>
            <div className='b-s-bank-acconts1'>
                <div className='b-s-personalBanking'>
                    <h1 style={{color: '#56B870'}}>Working Capital Financing</h1>
                    <p>Rayyan Bank understands the importance of working capital for businesses' day-to-day operations and growth. Our Working Capital Financing offers businesses the necessary funds to meet their short-term financing needs, such as managing cash flow, purchasing raw materials, covering operational expenses, or fulfilling orders. This financing solution is structured in a Shariah-compliant manner, providing businesses with the financial flexibility they require to seize opportunities and expand their operations.</p>
                </div>
                <div className='b-s-personal-images'>
                    <img src={image3} />
                </div>
            </div>
            <div style={{  backgroundColor: 'white', flexDirection:'row-reverse'}} className='b-s-bank-acconts1'>
                <div className='b-s-personalBanking'>
                    <h1 style={{color: '#56B870'}}>Ijarah Services</h1>
                    <p>Rayyan Bank's Ijarah Services provide a Shariah-compliant leasing solution for individuals and businesses seeking to acquire assets without taking on the burden of ownership. Under the Ijarah contract, the bank purchases the asset and leases it to the customer for an agreed-upon rental amount. The customer benefits from the use of the asset while the bank retains ownership. This financing option is particularly suitable for acquiring assets such as machinery, equipment, vehicles, or office space.</p>
                </div>
                <div className='b-s-personal-images1'>
                    <img src={image4} />
                </div>
                
            </div>
            <div className='b-s-bank-acconts1'>
                <div className='b-s-personalBanking'>
                    <h1 style={{color: '#56B870'}}>Inventory Financing</h1>
                    <p>For businesses in need of short-term financing to manage their inventory, Rayyan Bank offers Inventory Financing. This solution enables businesses to leverage their existing inventory as collateral to secure the required funds. By unlocking the value of their inventory, businesses can bridge cash flow gaps, optimize their stock levels, and meet customer demand promptly.</p>
                </div>
                <div className='b-s-personal-images'>
                    <img src={image5} />
                </div>
            </div>
        </div>
        <div className='b-s-bank-acconts-cta' style={{display:'flex', justifyContent:'space-between', alignItems:'center' , background:'#f3f3f3'}}>
            <h1> Related Links </h1>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
            <img src={caret1} />
                <HashLink to='/investment#investment'  style={{color:'#56B870', textDecoration: 'none'}}>Rayyan investment</HashLink>
            </div>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
            <img src={caret1} />
                <HashLink to='/bank#bank' style={{color:'#56B870', textDecoration: 'none'}}>Banking services</HashLink>
            </div>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
            <img src={caret1} />
                <HashLink to='/wakala#wakala' style={{color:'#56B870', textDecoration: 'none'}}>Wakala and Kafala</HashLink>
            </div>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
            <img src={caret1} />
                <HashLink to='/contact#contacttts'  style={{color:'#56B870', textDecoration: 'none'}}>Contact</HashLink>
            </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Financing
