import img1 from '../assets/about.jpg'
import img2 from '../assets/im-2.jpg'
import img3 from '../assets/imo3.jpg'
import img4 from '../assets/im-4.jpg'
import img5 from '../assets/im-5.jpg'
import img6 from '../assets/imo11.jpeg'
import img7 from '../assets/im-7.jpg'
import img8 from '../assets/im-8.jpg'
import img9 from '../assets/imo9.jpg'

export const cardsData = [
    {
        id: 0,
        title: 'Personal Banking' ,
        content:"GPI Financing stands out for their exceptional customer service. They took the time to understand my financial goals and provided personalized advice to help me make informed decisions.",
        cta: 'Learn More'
    },
    {
        id: 1,
        title: 'Business Banking' ,
        content: "GPI Financing offers a wide range of financing options tailored to meet specific business needs. Their team guided me through the process and helped me secure the funding I needed to expand my operations. Highly recommended.",
        cta: 'Learn More'
    },
    {
        id: 2,
        title: 'Ryyan Investment',
        content:"I had a great experience working with GPI Financing. They provided excellent financial solutions for my business, and their team was highly professional and responsive throughout the process.",
        cta: 'Learn More'
    },
    {
        id: 3,
        title: 'Ryyan Financing' ,
        content:"I had a great experience working with GPI Financing. They provided excellent financial solutions for my business, and their team was highly professional and responsive throughout the process.",
        cta: 'Learn More'
    },
]
export const accountData = [
    {
        id:0,
        img: img2,
        title: "Qard Current Account - Salary",
        content: 'This account allows employers to deposit salaries directly into employees accounts, ensuring timely and hassle-free transactions.'
    },
    {     
        id:1,
        img: img8,
        title: "Murabaha Asset Financing",
        content: 'Murabaha Asset Financing provides a Shariah-compliant solution for individuals and businesses looking to acquire fixed assets such as machinery, equipment, vehicles, or real estate.'
    },
    
    {
        id:2,
        img: img3,
        title: "Mudarabah Savings Account – Children",
        content: 'Our Mudarabah Savings Account for children offers a safe and rewarding avenue for young savers up to the age of 12 to start building their financial future.'
    },
    {
        id:3,
        img: img4,
        title: "Qard Current Account – Individual",
        content: 'Our Qard Current Account for individuals is specifically tailored to meet the banking requirements of self-employed professionals, entrepreneurs, and business owners. '
    },
    {
        id:4,
        img: img5,
        title: "Murabaha Inventory Financing",
        content: 'For businesses looking to finance their inventory or stock purchases, Rayyan Bank offers Murabaha Stock Financing. '
    },
    {
        id:5,
        img: img6,
        title: "Mudarabah Savings Account – Individual",
        content: 'Our Mudarabah Savings Account for individuals is tailored to meet the financial goals and aspirations of our adult customers. '
    },
    {
        id:6,
        img: img7,
        title: "Ijarah Services",
        content: 'Our Ijarah Services provide a Shariah-compliant leasing solution for individuals and businesses seeking to acquire assets without taking on the burden of ownership.'
    },
    {
        id:7,
        img: img9,
        title: "Kafala",
        content: 'Kafala Services are designed to facilitate the growth and development of businesses through cooperative partnerships.'
    },
]

