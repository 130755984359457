import React, {useState} from 'react'
import Navbar from '../../components/navbar/Navbar'
import banner from '../../assets/s-b.jpg'
import './servicesPage.css'
import { Modal, Button} from 'react-bootstrap';
import image from '../../assets/investment.jpg'
import { HashLink } from 'react-router-hash-link'
import caret1 from '../../assets/caret-right.svg'
import Footer from '../../components/footer/Footer'
import Stats from '../../components/stats/Stats'

import { Link } from 'react-router-dom';

const Investment = () => { 
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onButtonClick = () => {
      // using Java Script method to get PDF file
      fetch('corporate.pdf').then(response => {
          response.blob().then(blob => {
              // Creating new object of PDF file
              const fileURL = window.URL.createObjectURL(blob);
              // Setting various property values
              let alink = document.createElement('a');
              alink.href = fileURL;
              alink.download = 'corporate.pdf';
              alink.click();
          })
      })
      setShow(false)
  }

  const onButtonClick2 = () => {
      // using Java Script method to get PDF file
      fetch('individual.pdf').then(response => {
          response.blob().then(blob => {
              // Creating new object of PDF file
              const fileURL = window.URL.createObjectURL(blob);
              // Setting various property values
              let alink = document.createElement('a');
              alink.href = fileURL;
              alink.download = 'individual.pdf';
              alink.click();
          })
          
      })
      setShow(false)
  }
  return (
    <div className='BankingServices'>
        <Navbar />
      <div className='b-s-container'>
        <div className='about-header'>
            <div className='b-header-s-container'>
                <div id='investment' className='about-image' >
                <div className='about-opacity'></div>
                    <img id='aboutss' src={banner} />
                </div>
                <div className='b-s-title' data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                    <h1>Rayyan Investment</h1>
                    <p>Rayyan Bank provides Shariah-compliant financing options, including personal finance, home finance, auto finance, and working capital financing for businesses.</p>
                </div>
            </div>
        
        </div>
        <div className='b-s-open-account'>
            <h1>New Account Application </h1>
            <p>We are grateful for the chance to offer you our banking services and eagerly anticipate assisting you in meeting your financial needs.</p>
            <div className='b-s-open-account-link'>
                <button onClick={handleShow} style={{textDecoration: 'none', color:'white', background:'transparent', outline:'none', border:'none'}}> OPEN AN ACCOUNT ONLINE </button>
            </div>
        </div>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header >
          <Modal.Title style={{fontSize:'19px', fontWeight:'700'}}>Open a new account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='acct-modal'>
                <p style={{fontWeight:'500'}}>Click the button below to download our individual/corporate account opening forms. </p>
                    <div style={{display:'flex', flexDirection:'column'}}>
                    <div>
                        <button onClick={onButtonClick2} style={{textDecoration: 'none', color:'white', background:'#56B870', outline:'none', border:'none',width:'180px'}}> Individual Account </button>
                    </div>
                    <div>
                        <button onClick={onButtonClick} style={{textDecoration: 'none', color:'white', background:'#56B870', outline:'none', border:'none', marginTop:'1rem', width:'180px' }}> Corporate Account </button>
                    </div>
                    <div>
                       <Link to="https://ibank.rayyanbank.com.ng/" target="_blank" style={{textDecoration: 'none'}}> <div style={{display: 'flex', justifyContent: "center", alignItems: "center", color:'white', background:'#56B870', outline:'none', border:'none', marginTop:'1rem', width:'180px' , fontWeight:'normal'}}> Internet Banking </div> </Link>
                    </div>
                </div>  
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
        <div className='b-s-bank-acconts-container'>
            <div className='b-s-bank-acconts1'>
                <div className='b-s-personalBanking'>
                    <h1 style={{color: '#56B870'}}>Mudarabah Term Deposit</h1>
                    <p>Mudarabah Term Deposit investment services, provides individuals and businesses with a Shariah-compliant investment option that allows them to earn competitive returns on their funds. Our Mudarabah Term Deposit combines the principles of Mudarabah, where the bank acts as the entrepreneur (Mudarib), and the customer acts as the capital provider (Rab-ul-Mal), creating a mutually beneficial investment partnership.</p>
                </div>
                <div className='b-s-personal-images'>
                    <img src={image} />
                </div>
            </div>
        </div>
        <div className='b-s-bank-investment-container'>
          <h1>With Rayyan Bank’s Mudarabah Term Deposit, you can guarantee</h1>
          <div className='b-s-bank-investment-content-container'>
            <div className='b-s-bank-investment-content'>
              <h1>Flexible Tenures</h1>
              <p>We offer flexible tenure options for our Mudarabah Term Deposit investments, allowing customers to choose a term that aligns with their financial goals and investment strategies. Whether short-term or long-term, our deposit tenures provide the flexibility needed to suit individual preferences.</p>
            </div>
            <div className='b-s-bank-investment-content'>
              <h1>Competitive Returns</h1>
              <p>Rayyan Bank strives to provide attractive and competitive returns on Mudarabah Term Deposits. The profits generated from the investments made using the deposited funds are shared between the bank and the customer according to a pre-agreed profit-sharing ratio. We aim to maximize the returns for our customers while ensuring the investments remain in compliance with Shariah principles.</p>
            </div>
            <div className='b-s-bank-investment-content'>
              <h1>Secure Investment</h1>
              <p>Rayyan Bank is a licensed and regulated financial institution, providing peace of mind to investors who entrust their funds with us. We maintain robust risk management practices and stringent controls to safeguard the invested capital and generate steady returns for our customers.</p>
            </div>
            <div className='b-s-bank-investment-content'>
              <h1>Convenience and Ease of Use</h1>
              <p>Opening a Mudarabah Term Deposit with Rayyan Bank is a straightforward process. Customers can conveniently initiate and manage their investments through our digital platforms or by visiting our branches. Our user-friendly online banking and mobile banking services provide easy access to account statements, investment details, and customer support.</p>
            </div>
          </div>
        </div>
        <Stats />
        <div className='b-s-bank-acconts-cta' style={{display:'flex', justifyContent:'space-between', alignItems:'center' , background:'#f3f3f3'}}>
            <h1> Related Links </h1>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
            <img src={caret1} />
                <HashLink to='/bank#bank' style={{color:'#56B870', textDecoration: 'none'}}>Banking Services</HashLink>
            </div>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
            <img src={caret1} />
                <HashLink to='/finance#finance' style={{color:'#56B870', textDecoration: 'none'}}>Rayyan financing</HashLink>
            </div>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
            <img src={caret1} />
                <HashLink to='/wakala#wakala' style={{color:'#56B870', textDecoration: 'none'}}>Wakala and Kafala</HashLink>
            </div>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
            <img src={caret1} />
                <HashLink to='/contact#contacttts'  style={{color:'#56B870', textDecoration: 'none'}}>Contact</HashLink>
            </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Investment
