import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import Investment from "./pages/servicesPage/Investment";
import ScrollToTop from "react-scroll-to-top";
import BOD from "./pages/about/BOD";
import BankingServices from "./pages/servicesPage/BankingServices";
import Financing from "./pages/servicesPage/Financing";
import Wakala from "./pages/servicesPage/Wakala";
import Mgt from "./pages/about/Mgt";
import ACE from "./pages/about/ACE";


function App() {
  return (
    <BrowserRouter>
    <ScrollToTop smooth top="1500" />
      <Routes>
      {/* <Route path='/' element={<Navigate to= '/greenpath'/>} /> */}
      <Route path="/" element={<Home/>} />
        <Route path="/about-page" element={<About />} />
        <Route path="/ace" element={<ACE />} />
        <Route path="/mgt" element= {<Mgt />} />
        <Route path="/bod" element={<BOD />} />
        <Route path="/bank" element={<BankingServices/>} />
        <Route path="/investment" element={<Investment />} />
        <Route path="/finance" element={<Financing />} />
        <Route path="/wakala" element={<Wakala />} />
        <Route path="/contact" element={<Contact/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
