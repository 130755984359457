import React from 'react'
import image1 from '../../assets/online.jpg'
import image2 from '../../assets/check-bold.svg'
import icon5 from '../../assets/arrow-right.svg'
import './aboutrayyan.css'
import { HashLink } from 'react-router-hash-link';


const AboutRayyan = () => {
  return (
    <div className='about-rayyan'>
      <div className='about-rayyan-container'>
      <div className='about-rayyan-background'></div>
        <div className='about-rayyan-image'></div>
        <div className='about-rayyan-content'>
            <div className='about-rayyan-content-img'>
                <img src={image1}/>
            </div>
            
            <div className='about-rayyan-content-text'>
            <div className='reviews-content'>
                <div className='about-us-title'></div>
                <h3>About rayyan bank</h3>
            </div>
            <div className='about-rayyan-text'>
                <h1>Building A More Compact<br/> Future Economy</h1> 
                <p>Rayyan Bank is a non-interest (Islamic) bank licensed by the Central Bank of Nigeria (CBN) in August 2022. As an Islamic retail bank, Rayyan Bank provides a wide range of corporate, private, and investment banking services to individuals and enterprises. Our commitment to interest-free banking drives us to offer Shariah-compliant financial products and services through our extended digital platforms.</p>
                <div  className='about-rayyan-icon1-container' style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'flex-start', margin:'2.5rem 0 1.5rem' }}>
                    <div className='about-rayyan-icon1' style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center' }}>
                        <img src={image2} />
                        <h5>Interest Free</h5>
                    </div>
                    <div className='about-rayyan-icon1' style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center', marginLeft: '2rem' }}>
                        <img src={image2} />
                        <h5>Sharia Standards</h5>
                    </div>
                </div>
                <div className='about-rayyan-icon3' style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center' }}>
                        <p>We take pride in being a friendly face and voice that supports our customers throughout their banking journey.</p>
                </div>
                <div className='about-rayyan-cta' style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'flex-start'}}>
                    <HashLink style={{textDecoration: 'none', color:'white' }} to='/bank#bank'>Open an account</HashLink>
                    <img src={icon5}/>
                </div>
            </div>
            </div>
            <div>
    
            </div>
        </div>
      </div>
    </div>
  )
}

export default AboutRayyan
