import React, {useEffect, useState} from 'react'
import Footer from '../../components/footer/Footer'
import Navbar from '../../components/navbar/Navbar'
import './about.css'
import {Modal, Button} from 'react-bootstrap';
import banner from '../../assets/board.jpg'
import image0 from '../../assets/image.jpeg'
import image2 from '../../assets/tahir.jpeg'
import image3 from '../../assets/Picture2.png'
import image1 from '../../assets/ceo.jpeg'
import AOS from 'aos';
import 'aos/dist/aos.css';


const Mgt = () => {
const [show, setShow] = useState(false);
const [show1, setShow1] = useState(false);
const [show2, setShow2] = useState(false);
const [show3, setShow3] = useState(false);

const handleClose = () => setShow(false);
const handleShow = () => setShow(true); 

const handleClose1 = () => setShow1(false);
const handleShow1 = () => setShow1(true);

const handleClose2 = () => setShow2(false);
const handleShow2 = () => setShow2(true);

const handleClose3 = () => setShow3(false);
const handleShow3 = () => setShow3(true);


  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div className='about'>
    <div className='about-container'>
      <div className='about-header'>
          <Navbar />
          <div className='about-header-container'>
              <div className='about-image' >
              <div className='about-opacity'></div>
                <img id='aboutss' src={banner} />
              </div>
              <div className='about-title title' data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                <h1>Management Team</h1> 
              </div>
          </div>
      </div>
            <div className='about-contain'>
            <div className='about-content-contain'  data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
            <div className='about-conten'>
                <div className='aboutH-content-div1'>
                    <h1>Mr. Abubakar Musa</h1>
                    <span className='about-span'>MD/CEO</span>
                </div>
                <p>Mr. Abubakar Musa is an Associate Chartered Accountant from the Institute of Chartered Accountants of Nigeria (ICAN), Associate Member of the Chartered Institute of Taxation of Nigeria (CITN), Certified Islamic Finance Examiner from Ethica Institute of Islamic Finance and a Member of the Nigerian Institute of Management.</p>
                <button onClick={handleShow3} className='about-content-button'>Learn more</button>
                <Modal show={show3} onHide={handleClose3} centered>
                    <Modal.Header closeButton>
                    <Modal.Title style={{padding:'0 1rem'}}>Mr. Abubakar Musa</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{padding:'0 1rem'}}>Mr. Abubakar Musa is an Associate Chartered Accountant from the Institute of Chartered Accountants of Nigeria (ICAN), Associate Member of the Chartered Institute of Taxation of Nigeria (CITN), Certified Islamic Finance Examiner from Ethica Institute of Islamic Finance and a Member of the Nigerian Institute of Management.</p>
                        <p style={{padding:'0 1rem'}}>He is a holder of Bachelor of Science degree in Accountancy from University of Maiduguri, Master in Business Administration (Finance) from Abubakar Tafawa Balewa University, Bauchi. He also obtained Masters in Treasury Management from Bayero University, Kano in 2021. He has many Certifications which includes, Islamic Banking (Principles, Practice and Risk Management), Financial Modelling and Valuation, Proficiency certificate in Management, Accounting packages, Cyber Security and Leading High-Performing Teams.</p>
                        <p style={{padding:'0 1rem'}}>Abubakar Musa has over a decade of post graduate experience, worked with Unity Bank Plc as a Marketer, Zenith Bank Plc in Branch Operations Department, and also worked as an Accountant with Istrom Dynamics Project (Real Estate Developer). Before joining Rayyan in 2022, he worked with the Institute of Human Virology, Nigeria as the Regional Finance Officer (North-West Region).</p>
                    </Modal.Body>
                    
                </Modal>
            </div>
            <div className='about-content-image1'>
            <img src={image1} style={{objectPosition: '-20% 0' }} />
            </div>
            </div>
            <div className='about-content-contain'  data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
              <div className='about-conten'>
                  <div className='aboutH-content-div1'> 
                      <h1>Tahir Ibrahim Abacha</h1>
                      <span className='about-span'>Head Financial Control & Strategy</span>
                  </div>
                  <p>Tahir joins Rayyan Bank with over a decade experience as a professional Accountant having worked in both Private and Public sectors in Nigeria. He brings to the team hands on experience in Financial Reporting and Analysis, Treasury Operations, Risk Management and Business Strategy formulation and implementation.</p>
                  <button onClick={handleShow} className='about-content-button'>Learn more</button>
                  
              </div>
              <div className='about-content-image1'>
              <img src={image2} />
              </div>
          </div>
            <div className='about-content-contain'  data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
              <div className='about-conten'>
                  <div className='aboutH-content-div1'>
                      <h1>Mr. Ibrahim Yusuf Adamu</h1>
                      <span className='about-span'>Head, Operations & Internal Control</span>
                  </div>
                  <p>Ibrahim Yusuf Adamu is a passionate Islamic Finance Expert with cumulative banking experience (Conventional and Islamic) of over eight (8) years. He is a fellow of the Accounting and Auditing Organization of Islamic Financial Institutions (AAOIFI), a Certified Shari’ah Advisor and Auditor (CSAA), a member of the Chartered Institute of Islamic Finance Professionals.</p>
                  <button onClick={handleShow1} className='about-content-button'>Learn more</button>
                    
              </div>
              <div className='about-content-image1'>
              <img src={image3} />
              </div>
          </div>
          <div className='about-content-contain'  data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
              <div className='about-conten'>
                  <div className='aboutH-content-div1'>
                      <h1>Mal. Yusuf Adamu Toro</h1>
                      <span className='about-span'>Head, Shariah Audit & Compliance</span>
                  </div>
                  <p>Mal. Yusuf is an Islamic Scholar with vast experience in Fiqh/Shariah, he is also a banker. He worked with Taj Bank as a Shariah Auditor before resigning to join Rayyan. Mal. Yusuf has worked with Etiqa-Takaful Malaysia New Business Plan Review - Research Assistant, Skynet Travel Agency – Hajj and Umrah Coordinator. </p>
                  <button onClick={handleShow2} className='about-content-button'>Learn more</button>
                    
              </div>
              <div className='about-content-image1'>
              <img src={image0} />
              </div>
          </div>
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            >
                    <Modal.Header closeButton>
                      <Modal.Title style={{padding:'0 1rem'}}>Tahir Ibrahim Abacha BSc, PGDM, PPDA, MBA, CNA</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p style={{padding:'0 1rem'}}>Tahir joins Rayyan Bank with over a decade experience as a professional Accountant having worked in both Private and Public sectors in Nigeria. He brings to the team hands on experience in Financial Reporting and Analysis, Treasury Operations, Risk Management and Business Strategy formulation and implementation. An alumnus of the University of Maiduguri, Tahir holds a First degree in Accountancy and Post Graduate Diploma in Management and Postgraduate Professional Diploma in Accounting from Nigerian College of Accountancy, Jos. He also has an MBA (Finance & Investment) from Ahmadu Bello University, Zaria. He is a member of the Association of National Accountants of Nigeria (ANAN) and Chartered Institute of Taxation of Nigeria (CITN).</p>
                      <p style={{padding:'0 1rem'}}>Tahir started his career in 2010 as a Finance & Admin Manager at Attahir Enterprises Nigeria Ltd and later joined Federal Medical Centre, Azare in 2013 as an Accountant where he rose up to become the Head of Final Account of the Centre. In 2019, he joined the Industrial Training Fund (ITF) as an Accountant before joining the Rayyan team of highly skilled and experienced management trying to provide Customers with Shariah Compliant Products and proffer solutions through technological innovations as the first ever Digital Non-Interest Bank in Nigeria. He has undertaken several Conferences, trainings and seminars over the years. Tahir is presently the Head, Financial Control and Strategy of the Bank.</p>
                    </Modal.Body>
          </Modal>
          <Modal show={show1} onHide={handleClose1} centered>
                      <Modal.Header closeButton>
                      <Modal.Title style={{padding:'0 1rem'}}>Mr. Ibrahim Yusuf Adamu</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                          <p style={{padding:'0 1rem'}}>Ibrahim Yusuf Adamu is a passionate Islamic Finance Expert with cumulative banking experience (Conventional and Islamic) of over eight (8) years. He is a fellow of the Accounting and Auditing Organization of Islamic Financial Institutions (AAOIFI), a Certified Shari’ah Advisor and Auditor (CSAA), a member of the Chartered Institute of Islamic Finance Professionals. He has a certificate in Islamic Theology, Capital Market Operations, Islamic Liquidity Management Instruments, Treasury Management for Islamic Banks, Integrated Management System Masterclass.
                          </p>
                          <p style={{padding:'0 1rem'}}>Mr. Ibrahim Adamu has worked with StanbicIBTC Bank, Jaiz Bank Plc and TAJBank Limited. He has a HND in Soil & Water Engineering from Ramat Polytechnic Maiduguri, a Bachelor of Science degree in Public Administration from University of Maiduguri, he is currently pursuing a master’s degree in Islamic Finance Practice (MIFP) with the International Centre for Education in Islamic Finance (INCEIF) Global University, Kuala Lumpur, Malaysia. 
                          </p>
                      </Modal.Body>
                      
            </Modal>
            <Modal show={show2} onHide={handleClose2} centered>
              <Modal.Header closeButton>
              <Modal.Title style={{padding:'0 1rem'}}>Mal. Yusuf Adamu Toro</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p style={{padding:'0 1rem'}}>Mal. Yusuf is an Islamic Scholar with vast experience in Fiqh/Shariah, he is also a banker. He worked with Taj Bank as a Shariah Auditor before resigning to join Rayyan. Mal. Yusuf has worked with Etiqa-Takaful Malaysia New Business Plan Review - Research Assistant, Skynet Travel Agency – Hajj and Umrah Coordinator. </p>
                <p style={{padding:'0 1rem'}}>Mal. Yusuf is a Certified Shari’ah Auditor and Advisor (CSAA), he has two first degrees (Bsc. Microbiology and B.A Shariah Law) from University of Maiduguri and University of Madinah respectively. He has a Masters in Islamic Finance Practice, Malaysia in addition to several trainings, seminars and workshop. </p>
                      </Modal.Body>       
            </Modal>
        <Footer />
    </div> 
    </div> 
  )
}

export default Mgt
