import React, {useEffect, useState} from 'react'
import Footer from '../../components/footer/Footer'
import Navbar from '../../components/navbar/Navbar'
import './about.css'
import {Modal, Button} from 'react-bootstrap';
import banner from '../../assets/board.jpg'
import image2 from '../../assets/sh2.jpeg'
import image3 from '../../assets/sh3.jpeg'
import image1 from '../../assets/sh1.jpeg'
import AOS from 'aos';
import 'aos/dist/aos.css';


const ACE= () => {
const [show, setShow] = useState(false);
const [show1, setShow1] = useState(false);
const [show2, setShow2] = useState(false);
const [show3, setShow3] = useState(false);

const handleClose = () => setShow(false);
const handleShow = () => setShow(true); 

const handleClose1 = () => setShow1(false);
const handleShow1 = () => setShow1(true);

const handleClose2 = () => setShow2(false);
const handleShow2 = () => setShow2(true);

const handleClose3 = () => setShow3(false);
const handleShow3 = () => setShow3(true);


  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div className='about'>
    <div className='about-container'>
      <div className='about-header'>
          <Navbar />
          <div className='about-header-container'>
              <div className='about-image' >
              <div className='about-opacity'></div>
                <img id='ace' src={banner} />
              </div>
              <div className='about-title title' data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                <h1>ACE Management</h1>
              </div>
          </div>
      </div>
            <div className='about-contain'>
            <div className='about-content-contain'  data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
            <div className='about-conten'>
                <div className='aboutH-content-div1'>
                    <h1>Sheikh Muhammad Mustapha</h1>
                    <span className='about-span'>Chairman</span>
                </div>
                <p>Sheikh Muhammad Mustapha is an outstanding Islamic scholar with over 3 decades of experience in preaching Islam. He received his degree at the renowned Islamic University of Madina, KSA where he earned a Bachelor's degree in Islamic studies.</p>
                <button onClick={handleShow3} className='about-content-button'>Learn more</button>
                <Modal show={show3} onHide={handleClose3} centered>
                    <Modal.Header closeButton>
                    <Modal.Title style={{padding:'0 1rem'}}>Sheikh Muhammad Mustapha</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{padding:'0 1rem'}}>Sheikh Muhammad Mustapha is an outstanding Islamic scholar with over 3 decades of experience in preaching Islam. He received his degree at the renowned Islamic University of Madina, KSA where he earned a Bachelor's degree in Islamic studies. He also rose up to the position of Ministerial secretary in the Borno state civil service and worked in various departments under the ministry of Religious affairs.</p>
                        <p style={{padding:'0 1rem'}}>In recognition of his outstanding performance and contributions, Sheikh Muhammad Mustapha was appointed as the Special Adviser (Religious Affairs) to the Borno State Governor. In this crucial role, he leveraged his extensive knowledge of Islamic matters to provide valuable insights and guidance to the state government. He is currently the chief imam of Bukhari Mosque Maiduguri, member Borno state Islamic preaching board and Director Al-amin Islamic foundation.</p>
                    </Modal.Body>
                    
                </Modal>
            </div>
            <div className='about-content-image1'>
            <img src={image1} />
            </div>
            </div>
            <div className='about-content-contain'  data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
              <div className='about-conten'>
                  <div className='aboutH-content-div1'> 
                      <h1>Adam Abubakar Mustapha Muhammad</h1>
                      <span className='about-span'>Member</span>
                  </div>
                  <p>Adam Abubakar Mustapha Muhammad is Assistant Lecturer with the Nigeria Arabic Language Village in Ngala, Borno State. He obtained his Bachelor's degree in Arabic and Islamic studies from the esteemed Al-Azhar University in Cairo, Egypt, Masters in Arabic and linguistics from the Yobe state university, Nigeria.</p>
                  <button onClick={handleShow} className='about-content-button'>Learn more</button>
                  
              </div>
              <div className='about-content-image1'>
              <img src={image2} />
              </div>
          </div>
            <div className='about-content-contain'  data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
              <div className='about-conten'>
                  <div className='aboutH-content-div1'>
                      <h1>Umar Ahmad Gajiram</h1>
                      <span className='about-span'>Member</span>
                  </div>
                  <p>Umar Ahmad is a judge who demonstrated his commitment to upholding justice and the rule of law under Shariah  and Islamic preacher. He is a graduate at the prestigious University of Maiduguri where he obtained a degree in Islamic Studies and Masters degree in Islamic studies from the University of Jos.</p>
                  <button onClick={handleShow1} className='about-content-button'>Learn more</button>
                    
              </div>
              <div className='about-content-image1'>
              <img src={image3} />
              </div>
          </div>
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            >
                    <Modal.Header closeButton>
                      <Modal.Title style={{padding:'0 1rem'}}>Adam Abubakar Mustapha Muhammad</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p style={{padding:'0 1rem'}}>Adam Abubakar Mustapha Muhammad is Assistant Lecturer with the Nigeria Arabic Language Village in Ngala, Borno State. He obtained his Bachelor's degree in Arabic and Islamic studies from the esteemed Al-Azhar University in Cairo, Egypt, Masters in Arabic and linguistics from the Yobe state university, Nigeria. In addition, he obtained a certificate of training course for Islamic science and prosody from Cairo university.</p>
                      <p style={{padding:'0 1rem'}}>He is an individual whose academic and professional feat reflects a deep commitment to Arabic and Islamic studies. His education there offered him a solid foundation in both languages and religious studies. Sequel to his studies, Adam Abubakar kick-started a career in education. He served as academic staff in various schools, delivering his expertise in Arabic, Islamic studies and Islamic jurisprudence to students.</p>
                    </Modal.Body>
          </Modal>
          <Modal show={show1} onHide={handleClose1} centered>
                      <Modal.Header closeButton>
                      <Modal.Title style={{padding:'0 1rem'}}>Umar Ahmad Gajiram</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                          <p style={{padding:'0 1rem'}}>Umar Ahmad is a judge who demonstrated his commitment to upholding justice and the rule of law under Shariah  and Islamic preacher. He is a graduate at the prestigious University of Maiduguri where he obtained a degree in Islamic Studies and Masters degree in Islamic studies from the University of Jos.
                          </p>
                          <p style={{padding:'0 1rem'}}>Umar Ahmad started his career as a school teacher showcasing his dedication to nurturing young minds as well as building their frame of mind upon the teachings of Islam. He has created Islamic awareness in his weekly lectures at Bukar Shuaib Mosque, Maiduguri where he also delivers Tafsir of the Qur’an during the Month of Ramadan. He is currently an upper Shariah Court judge in the Borno State Judiciary.  
                          </p>
                      </Modal.Body>
                      
            </Modal>

        <Footer />
    </div> 
    </div> 
  )
}

export default ACE
