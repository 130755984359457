import React, {useEffect, useState} from 'react'
import Footer from '../../components/footer/Footer'
import Navbar from '../../components/navbar/Navbar'
import './about.css'
import Modal from 'react-bootstrap/Modal';
import banner from '../../assets/board.jpg'
import image from '../../assets/Picture4.png'
import image1 from '../../assets/bod1.jpeg'
import image2 from '../../assets/PictA.png'
import image3 from '../../assets/Picture7.png'
import image4 from '../../assets/Picture8.png'
import image5 from '../../assets/ceo.jpeg'
import AOS from 'aos';
import 'aos/dist/aos.css'; 


const BOD = () => {
const [show, setShow] = useState(false);
const [show1, setShow1] = useState(false);
const [show2, setShow2] = useState(false);
const [show3, setShow3] = useState(false);
const [show4, setShow4] = useState(false);
const [show5, setShow5] = useState(false);

const handleClose = () => setShow(false);
const handleShow = () => setShow(true); 

const handleClose1 = () => setShow1(false);
const handleShow1 = () => setShow1(true);

const handleClose2 = () => setShow2(false);
const handleShow2 = () => setShow2(true);

const handleClose3 = () => setShow3(false);
const handleShow3 = () => setShow3(true);

const handleClose4 = () => setShow4(false);
const handleShow4 = () => setShow4(true);

const handleClose5 = () => setShow5(false);
const handleShow5 = () => setShow5(true);

  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div className='about'>
    <div className='about-container'>
      <div className='about-header'>
          <Navbar />
          <div className='about-header-container'>
              <div className='about-image' >
              <div className='about-opacity'></div>
                <img id='aboutss' src={banner} />
              </div>
              <div className='about-title title' data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                <h1>Board of Directors</h1>
              </div>
          </div>
      </div>
      <div className='about-contain1'>
      <div className='about-content-contain-bod'  data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
            <div className='about-conten'>
                <div className='aboutH-content-div1'>
                    <h1>Dr. Usman Mohammed Shettima</h1>
                    <span className='about-span'>Founder/Chairman</span>
                </div>
                <p style={{maxWidth:'550px' }}>Dr. Usman Shettima is a distinguished Accounting and Finance professional with over 13 years cognate experience. He holds Doctor of Philosophy degree in Accounting and Finance, MSc in Islamic Banking and Finance both from Salford University, Manchester, United Kingdom.</p>
                <button onClick={handleShow1} className='about-content-button'>Learn more</button>
                <Modal show={show1} onHide={handleClose1} centered>
                    <Modal.Header closeButton>
                    <Modal.Title style={{padding:'0 1rem'}}>Dr. Usman Mohammed Shettima</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{padding:'0 1rem'}}>Dr. Usman Shettima is a distinguished Accounting and Finance professional with over 13 years cognate experience. He holds Doctor of Philosophy degree in Accounting and Finance, MSc in Islamic Banking and Finance both from Salford University, Manchester, United Kingdom and BSc Accountancy from the prestigious University of Maiduguri. Dr. Usman holds a Postgraduate Professional diploma in Accounting from the esteemed Association of National Accountants of Nigeria. He is a member of the Nigerian Institute of Management (NIM), the Chartered Institute of Taxation of Nigeria (CITN), and a member of the Association of National Accountant of Nigeria (ANAN).</p>
                        <p style={{padding:'0 1rem'}}>He began his career in 2010 at Gotiri Construction Services where he served for 3 years as an accountant. He joined Ramat Polytechnic Maiduguri in 2013 as a lecturer in the department of banking and finance. In august 2017, he joined the Central Bank of Nigeria where he was instrumental in the phenomenal growth of the non-Interest banking and finance sector in Nigeria. His expertise spans policy formulation, regulatory compliance, corporate governance, banking supervision, development and inclusive financing, data mining and analysis, strategy development and risk management.</p>
                        <p style={{padding:'0 1rem'}}>He is currently the founder & Chairman of Rayyan Bank. Nigeria's first ever Digital Non-Interest bank. Dr. Usman has attended various conferences, trainings/workshops, and courses in the last 10 years. He has equally published in various local and international journals.</p>
                    </Modal.Body>
                    
                </Modal>
            </div>
            <div className='about-content-image1'>
            <img src={image1} />
            </div>
        </div>
        <div className='about-content-contain-bod'  data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
            <div className='about-conten'>
                <div className='aboutH-content-div1'>
                    <h1>Mr. Abubakar Musa</h1>
                    <span className='about-span'>MD/CEO</span>
                </div>
                <p>Mr. Abubakar Musa is an Associate Chartered Accountant from the Institute of Chartered Accountants of Nigeria (ICAN), Associate Member of the Chartered Institute of Taxation of Nigeria (CITN), Certified Islamic Finance Examiner from Ethica Institute of Islamic Finance and a Member of the Nigerian Institute of Management.</p>
                <button onClick={handleShow5} className='about-content-button'>Learn more</button>
                <Modal show={show5} onHide={handleClose5} centered>
                    <Modal.Header closeButton>
                    <Modal.Title style={{padding:'0 1rem'}}>Mr. Abubakar Musa</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{padding:'0 1rem'}}>Mr. Abubakar Musa is an Associate Chartered Accountant from the Institute of Chartered Accountants of Nigeria (ICAN), Associate Member of the Chartered Institute of Taxation of Nigeria (CITN), Certified Islamic Finance Examiner from Ethica Institute of Islamic Finance and a Member of the Nigerian Institute of Management.</p>
                        <p style={{padding:'0 1rem'}}>He is a holder of Bachelor of Science degree in Accountancy from University of Maiduguri, Master in Business Administration (Finance) from Abubakar Tafawa Balewa University, Bauchi. He also obtained Masters in Treasury Management from Bayero University, Kano in 2021. He has many Certifications which includes, Islamic Banking (Principles, Practice and Risk Management), Financial Modelling and Valuation, Proficiency certificate in Management, Accounting packages, Cyber Security and Leading High-Performing Teams.</p>
                        <p style={{padding:'0 1rem'}}>Abubakar Musa has over a decade of post graduate experience, worked with Unity Bank Plc as a Marketer, Zenith Bank Plc in Branch Operations Department, and also worked as an Accountant with Istrom Dynamics Project (Real Estate Developer). Before joining Rayyan in 2022, he worked with the Institute of Human Virology, Nigeria as the Regional Finance Officer (North-West Region).</p>
                    </Modal.Body>
                    
                </Modal>
            </div>
            <div className='about-content-image1'>
            <img src={image5}  style={{objectPosition: '-20% 0' }}/>
            </div>
            </div>
        <div className='about-content-contain-bod'  data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
            <div className='about-conten'>
                <div className='aboutH-content-div1'>
                    <h1>Dr. Babagana Mohammed Adam</h1>
                    <span className='about-span'>Non-Executive Director</span>
                </div>
                <p style={{maxWidth: '550px'}}>Dr. Babagana Mohammed Adam is a renowned business man, he began his career as Founder and Managing Director of Yaulari Ventures Ltd a position he holds from 2006-2017. He has over a decade experience in construction and estate development business.</p>
                <button onClick={handleShow} className='about-content-button'>Learn more</button>
                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                    <Modal.Title style={{padding:'0 1rem'}}>Dr. Babagana Mohammed Adam</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{padding:'0 1rem'}}>Dr. Babagana Mohammed Adam is a renowned business man, he began his career as Founder and Managing Director of Yaulari Ventures Ltd a position he holds from 2006-2017. He has over a decade experience in construction and estate development business. Dr. Babagana is the Managing Director/CEO of African Institute of One Health Research and Diagnostics, a position he assume in 2017-till date. He is a Director in many companies.</p>
                        <p style={{padding:'0 1rem'}}>Dr. Babagana is a Doctor of Veterinary medicine from University of Maiduguri, PHD holder in Ecology and Molecular Epidemiology of Tick-borne Infectious Disease in Nigeria from University of Salford, Manchester, United Kingdom. MSc. Environmental and Public Health from University of Salford Manchester, United Kingdom. He is a member of many professional bodies including Nigeria Veterinary Medical Association.</p>
                    </Modal.Body>
                    
                </Modal>
            </div>
            <div className='about-content-image1'>
            <img src={image} />
            </div>
        </div>
        <div className='about-content-contain-bod'  data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
            <div className='about-conten'>
                <div className='aboutH-content-div1'>
                    <h1>Alh. Zanna Zakaria Idris</h1>
                    <span className='about-span'>Non-Executive Director</span>
                </div>
                <p style={{maxWidth:'550px' }}>Zanna Zakaria Idris is a renowned businessman and an administrator with great wealth of experience in the Business World. He is known as a stickler for corporate values and for his strategic methodologies for turning businesses around in the simplest but strategic way towards achieving corporate goals.</p>
                <button onClick={handleShow2} className='about-content-button'>Learn more</button>
                <Modal show={show2} onHide={handleClose2} centered>
                    <Modal.Header closeButton>
                    <Modal.Title style={{padding:'0 1rem'}}>Alh. Zanna Zakaria Idris</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{padding:'0 1rem'}}>Zanna Zakaria Idris is a renowned businessman and an administrator with great wealth of experience in the Business World. He is known as a stickler for corporate values and for his strategic methodologies for turning businesses around in the simplest but strategic way towards achieving corporate goals.</p>
                        <p style={{padding:'0 1rem'}}>He is the Managing Director/Chief Executive Officer of Kain Energy Ltd. and sits on the board of other companies within and outside Nigeria. He obtained his Bachelor of Science Degree in Business Management from University of Maiduguri in 2002 and has attended several professional courses and seminars. He is the holder of the prestigious Traditional title of ‘Wali of Borno’.</p>
                    </Modal.Body>
                    
                </Modal>
            </div>
            <div className='about-content-image1'>
            <img src={image2} />
            </div>
        </div>
        <div className='about-content-contain-bod'  data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
            <div className='about-conten'>
                <div className='aboutH-content-div1'>
                    <h1>Mr. Bukar Umar Bolori</h1>
                    <span className='about-span'>Independent Non-Executive Director</span>
                </div>
                <p style={{maxWidth:'550px' }}>Mr. Bukar Umar Bolori holds a Master of Science (MSc) in Management and Finance from Bangor University, United Kingdom and Master of Business Administration (MBA) in Marketing, Postgraduate Degree in Management (PGDM) and Postgraduate Degree in Education (PGDE) all from University of Maiduguri, Nigeria. Also, later Studied BSc Banking & Finance from ABU, Zaria, Kaduna State, Nigeria.</p>
                <button onClick={handleShow3} className='about-content-button'>Learn more</button>
                <Modal show={show3} onHide={handleClose3} centered>
                    <Modal.Header closeButton>
                    <Modal.Title style={{padding:'0 1rem'}}>Mr. Bukar Umar Bolori</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{padding:'0 1rem'}}>Mr. Bukar Umar Bolori holds a Master of Science (MSc) in Management and Finance from Bangor University, United Kingdom and Master of Business Administration (MBA) in Marketing, Postgraduate Degree in Management (PGDM) and Postgraduate Degree in Education (PGDE) all from University of Maiduguri, Nigeria. Also, later Studied BSc Banking & Finance from ABU, Zaria, Kaduna State, Nigeria. He worked as an Assistant Sales Manager of Bolori Commercial Enterprises Ltd. He also worked in Pioneer Enamelware Industries, Maiduguri as an Assistant Accountant dealing with enamelware records.</p>
                        <p style={{padding:'0 1rem'}}>Professionally, he has been working in academics for over 20 years lecturing Pre National Diploma (PreND), National Diploma (ND) and Higher National Diploma (HND) courses covering a wide range of topics in Business Management, Marketing, Computer, Banking and Finance including Islamic Courses. He served as a Class Councilor with responsibility of handling students problems of Pre-ND Studies Department, Ramat Polytechnic Maiduguri. Recently served as The Head of Department, Banking & Finance Department. Presently working as a lecturer of Department of Banking and Finance under the School of Management, Ramat Polytechnic Maiduguri.</p>
                        <p style={{padding:'0 1rem'}}>Mr. Bolori belongs to many professional bodies, including an Associate Member - National Institute of Management (NIM), Fellow - Chartered Institute of Public Diplomacy and Management (IPDM), Fellow - Institute of Professional Managers and Administrators of Nigeria (IPMA), Fellow - Institution of Business Diplomacy and Financial Management (IBDFM), Member - Digital Bridge Institute, Advanced Digital Appreciation Programme for Tertiary Institutions, Certified Teacher - Teachers Registration Council Of Nigeria, Chartered Institute of Bankers of Nigeria (CIBN).</p>
                    </Modal.Body>
                </Modal>
            </div>
            <div className='about-content-image1'>
            <img src={image3} />
            </div>
        </div>
        <div className='about-content-contain-bod'  data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
            <div className='about-conten'>
                <div className='aboutH-content-div1'>
                    <h1>Nana-Aisha Ahmed Yusufari</h1>
                    <span className='about-span'>Non-Executive Director</span>
                </div>
                <p style={{maxWidth:'550px' }}>Nana-Aisha Yusufari is a former banker. She started her banking career from 2003 with First Bank of Nigeria as a Senior Banking Assistant, before moving to Eco Bank Plc as a customer service manager and later moved to Jaiz Bank as Branch Service Manager.</p>
                <button onClick={handleShow4} className='about-content-button'>Learn more</button>
                <Modal show={show4} onHide={handleClose4} centered>
                    <Modal.Header closeButton>
                    <Modal.Title style={{padding:'0 1rem'}}>Nana-Aisha Ahmed Yusufari</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{padding:'0 1rem'}}>Nana-Aisha Yusufari is a former banker. She started her banking career from 2003 with First Bank of Nigeria as a Senior Banking Assistant, before moving to Eco Bank Plc as a customer service manager and later moved to Jaiz Bank as Branch Service Manager. She is also a consultant to NITDA from 2022 to date. Nana-Aisha holds a Bachelors of Art from University of Petra, Jordan. </p>
                    </Modal.Body>
                    
                </Modal>
            </div>
            <div className='about-content-image1'>
            <img src={image4} />
            </div>
        </div>
    </div>
        <Footer />
    </div> 
    </div> 
  )
}

export default BOD
