import React, {useEffect} from 'react'
import Footer from '../../components/footer/Footer'
import Map from '../../components/map/Map'
import Navbar from '../../components/navbar/Navbar'
import './contact.css'
import banner from '../../assets/cont.jpeg' 
import icon1 from '../../assets/phone1.png'
import icon2 from '../../assets/earth.png'
import icon3 from '../../assets/plane.png'
import { AiFillTwitterCircle, AiOutlineInstagram } from "react-icons/ai";
import {FaFacebookF} from "react-icons/fa";
import AOS from 'aos';
import 'aos/dist/aos.css';
import image2 from '../../assets/hijabi1.jpeg'
    const contactData = [
      {
        id: 1,
        img: icon1 ,
        tel: '090X XXX XXXX' ,
        content: 'Support 24/7 - Online 24 hours'
      },
      {
        id: 2,
        img: icon2 ,
        tel: 'No. 3 Tito Bros Street, Asokoro Abuja' ,
        content: 'rayyanbank@gmail.com'
      },
      {
        id: 3,
        img: icon3 ,
        tel: '' ,
        content: 'Mon - Fri: 8:00am - 4pm'
      }
    ]
const Contact = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div className='contact'>
      <div className='contact-container'>
        <div className='contact-header'>
        <Navbar />
        <div className='contact-header-container'>
            <div className='contact-image' >
            <div className='contact-opacity'></div>
              <img src={banner} id='contacttts' />
            </div>
            <div className='contact-title' data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
              <h1>Contact Us</h1>
            </div>
          </div>
        </div>
        <div className='contact-content' data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
        <h1>Contact Us Now</h1>
          <p>Feel free to reach out to us with any questions, concerns, or inquiries you may have. We value your feedback and are here to assist you in any way we can. </p>
        </div>
        <div className='contact-details' data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
            <div className='contact-details-content'>
            <h1>Info Details:</h1>
              <div className='contact-details-text'>
                <p>Tel: +234-9049712650</p>
                <p>Email: info@rayyanmfb.com</p>
                <p>Address: Plot 1, Beside Bama Motor Park, Bama Road, Maiduguri</p>
                <p>Working hours: Mon - Fri: 8:00am - 4:00pm</p>
              </div>
            </div>
            <div className='contact-images'>
              <img src={image2} alt='contact image' />
            </div>
         </div>
        <div className='contact-address'>
            <div className='contact-form' >
              <h1>Send Us A Message</h1>
              <form >
                <input type='text' placeholder='Name' />
                <input type='email' placeholder='Email' />
                <input type='text' placeholder='Subject' />
                <textarea placeholder='Message' />
                <button>Submit</button>
              </form>
            </div>
        </div>
       <div className='contact-map'>
        <Map />
        </div>
        <Footer /> 
      </div>
    </div>
  )
}

export default Contact
