import React,{useState} from 'react'
import Navbar from '../../components/navbar/Navbar'
import banner from '../../assets/s-b.jpg'
import './servicesPage.css'
import {Accordion, Modal, Button} from 'react-bootstrap';
import image from '../../assets/new-img1.jpg'
import image2 from '../../assets/imo9.jpg'
import { HashLink } from 'react-router-hash-link'
import caret1 from '../../assets/caret-right.svg'
import Footer from '../../components/footer/Footer'

import { Link } from 'react-router-dom';

const BankingServices = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onButtonClick = () => {
        // using Java Script method to get PDF file
        fetch('corporate.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'corporate.pdf';
                alink.click();
            })
        })
        setShow(false)
    }

    const onButtonClick2 = () => {
        // using Java Script method to get PDF file
        fetch('individual.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'individual.pdf';
                alink.click();
            })
            
        })
        setShow(false)
    }
  return (
    <div className='BankingServices'>
        <Navbar />
      <div id='bank' className='b-s-container'>
        <div className='about-header'>
            <div className='b-header-s-container'>
                <div className='about-image' >
                <div className='about-opacity'></div>
                    <img id='aboutss' src={banner} />
                </div>
                <div className='b-s-title' data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                    <h1>Rayyan Banking</h1>
                    <p>Indeed, recognizing the individuality of each person is crucial, as there is no universal solution that suits everyone. This is precisely why we offer an extensive range of Shariah-compliant financial products that cater to a diverse array of needs. </p>
                </div> 
            </div>
        
        </div>
        <div className='b-s-open-account'>
            <h1>New Account Application </h1>
            <p>We are grateful for the chance to offer you our banking services and eagerly anticipate assisting you in meeting your financial needs.</p>
            <div className='b-s-open-account-link'>
                <button onClick={handleShow} style={{textDecoration: 'none', color:'white', background:'transparent', outline:'none', border:'none'}}> OPEN AN ACCOUNT ONLINE </button>
            </div>
        </div>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header >
          <Modal.Title style={{fontSize:'19px', fontWeight:'700'}}>Open a new account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='acct-modal'>
                <p style={{fontWeight:'500'}}>Click the button below to download our individual/corporate account opening forms. </p>
                    <div style={{display:'flex', flexDirection:'column'}}>
                    <div>
                        <button onClick={onButtonClick2} style={{textDecoration: 'none', color:'white', background:'#56B870', outline:'none', border:'none',width:'180px'}}> Individual Account </button>
                    </div>
                    <div>
                        <button onClick={onButtonClick} style={{textDecoration: 'none', color:'white', background:'#56B870', outline:'none', border:'none', marginTop:'1rem', width:'180px' }}> Corporate Account </button>
                    </div>
                    <div>
                       <Link to="https://ibank.rayyanbank.com.ng/" target="_blank" style={{textDecoration: 'none'}}> <div style={{display: 'flex', justifyContent: "center", alignItems: "center", color:'white', background:'#56B870', outline:'none', border:'none', marginTop:'1rem', width:'180px' , fontWeight:'normal'}}> Internet Banking </div> </Link>
                    </div>
                </div>  
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
        <div className='b-s-bank-acconts-container'>
            <div className='b-s-bank-acconts'>
                <div className='b-s-personalBanking'>
                    <h1 style={{color: '#56B870'}}>Personal Banking</h1>
                    <p>Explore a range of Savings Account options tailored to suit your financial aspirations and lifestyle. Our Savings Account services are designed to provide you with the flexibility, security, and convenience you deserve.</p>
                    <div className='b-s-accordion'>
                    <Accordion defaultActiveKey="-1" flush>
        <Accordion.Item eventKey="0" style={{backgroundColor:'transparent'}}>
            <Accordion.Header>Mudarabah Savings Account – Individual</Accordion.Header>
            <Accordion.Body>
            Our Mudarabah Savings Account for individuals is tailored to meet the financial goals and aspirations of our adult customers. This account operates on the principle of Mudarabah, where customers act as the capital provider (Rab-ul-Mal) while the bank acts as the entrepreneur (Mudarib). The profits generated from the investments made using the deposited funds are shared between the account holder and the bank according to a pre-agreed profit-sharing ratio. This account offers competitive profit rates, flexible withdrawal options, and the peace of mind that comes with knowing your savings are being managed in a Shariah-compliant manner.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1" style={{backgroundColor:'transparent'}}>
            <Accordion.Header> Mudarabah Savings Account - Youth</Accordion.Header>
            <Accordion.Body>
            We believe in nurturing a culture of financial responsibility from a young age. Our Mudarabah Savings Account for youth is designed to provide young individuals (typically between the ages of 13 and 17) with a platform to start saving and developing healthy financial habits. This account not only helps youth understand the value of money but also gives them the opportunity to earn a return on their savings. With this account, young customers can enjoy features such as low minimum balance requirements, personalized debit cards, and access to educational resources on financial literacy.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2" style={{backgroundColor:'transparent'}}>
            <Accordion.Header> Mudarabah Savings Account - Children</Accordion.Header>
            <Accordion.Body>
            At Rayyan Bank, we understand the importance of instilling financial awareness and discipline in children. Our Mudarabah Savings Account for children offers a safe and rewarding avenue for young savers up to the age of 12 to start building their financial future. This account promotes a savings mindset and teaches children the value of money management. With a low minimum balance requirement, attractive gift incentives, and engaging activities, we make saving fun and educational for children. Parents or guardians can open and manage this account on behalf of their children, fostering financial responsibility from an early age.
            </Accordion.Body>
        </Accordion.Item>
                    </Accordion>
                    </div>
                </div>
                <div className='b-s-personal-images'>
                    <img src={image} />
                </div>
            </div>
            <div style={{  backgroundColor: 'white', flexDirection:'row-reverse'}} className='b-s-bank-acconts'>
                <div className='b-s-personalBanking'>
                    <h1 style={{color: '#56B870'}}>Business Banking </h1>
                    <p>Rayyan Bank is dedicated to providing comprehensive and Shariah-compliant financial solutions to meet the diverse needs of businesses. Our Business Banking services include a range of Current Account products designed to support the financial operations of individuals, groups/associations, corporations, and facilitate salary payments.</p>
                    <div className='b-s-accordion'>
                    <Accordion defaultActiveKey="-1" flush>
        <Accordion.Item eventKey="0" style={{backgroundColor:'transparent'}}>
            <Accordion.Header>Qard Current Account – Individual</Accordion.Header>
            <Accordion.Body>
            Our Qard Current Account for individuals is specifically tailored to meet the banking requirements of self-employed professionals, entrepreneurs, and business owners. This account operates on the Qard principle, which refers to a benevolent loan given by the bank to the account holder. This means that the bank provides a secure account and payment services without any interest charges. With the Qard Current Account, individuals can access a wide range of banking services, such as checkbook facilities, debit cards, online banking, and mobile banking, to manage their day-to-day financial transactions efficiently.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1" style={{backgroundColor:'transparent'}}>
            <Accordion.Header>Qard Current Account – Groups/Associations</Accordion.Header>
            <Accordion.Body>
            At Rayyan Bank, we understand the unique needs of groups and associations, such as clubs, societies, and non-profit organizations. Our Qard Current Account for groups/associations offers a convenient and transparent banking solution for managing collective finances. This account enables groups/associations to receive and make payments, issue checks, and access online banking services. By adhering to the principles of Qard, we ensure that your financial transactions remain interest-free and compliant with Shariah guidelines.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2" style={{backgroundColor:'transparent'}}>
            <Accordion.Header>Qard Current Account - Corporate</Accordion.Header>
            <Accordion.Body>
            For corporations and businesses of various sizes, Rayyan Bank provides the Qard Current Account - Corporate. This account offers comprehensive banking solutions tailored to meet the financial needs of companies, including sole proprietorships, partnerships, and limited liability companies. With the Qard Current Account - Corporate, businesses can enjoy features such as dedicated relationship managers, business debit cards, access to business financing, and convenient online banking services. Our focus on Shariah-compliance ensures that your business transactions are conducted in an ethical and transparent manner.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3" style={{backgroundColor:'transparent'}}>
            <Accordion.Header>Qard Current Account - Salary</Accordion.Header>
            <Accordion.Body>
            Rayyan Bank understands the importance of smooth salary management for businesses and employees. Our Qard Current Account - Salary provides an efficient and compliant solution for salary payments. This account allows employers to deposit salaries directly into employees' accounts, ensuring timely and hassle-free transactions. Employees benefit from the convenience of accessing their salaries through ATMs, debit cards, and online banking services.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4" style={{backgroundColor:'transparent'}}>
            <Accordion.Header>Qard Savings Account</Accordion.Header>
            <Accordion.Body>Qard Savings account offers a Shariah compliant account with guaranteed deposit. It offers convenience to customers similar to other savings accounts product such a the sue of electronic banking channels as well as unlimited withdrawal to the extent of the funds available in the account. </Accordion.Body>
        </Accordion.Item>
                    </Accordion>
                    </div>
                </div>
                <div className='b-s-personal-images1'>
                    <img src={image2} />
                </div>
            </div>
        </div>
        <div className='b-s-bank-acconts-cta' style={{display:'flex', justifyContent:'space-between', alignItems:'center' , background:'#f3f3f3'}}>
            <h1> Related Links </h1>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
            <img src={caret1} />
                <HashLink to='/investment#investment' style={{color:'#56B870', textDecoration: 'none'}}>Rayyan investment</HashLink>
            </div>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
            <img src={caret1} />
                <HashLink to='/finance#finance' style={{color:'#56B870', textDecoration: 'none'}}>Rayyan financing</HashLink>
            </div>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
            <img src={caret1} />
                <HashLink to='/wakala#wakala' style={{color:'#56B870', textDecoration: 'none'}}>Wakala and Kafala</HashLink>
            </div>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
            <img src={caret1} />
                <HashLink to='/contact#contacttts' style={{color:'#56B870', textDecoration: 'none'}}>Contact</HashLink>
            </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default BankingServices
