import React, {useEffect, useState} from 'react'
import Footer from '../../components/footer/Footer'
import Navbar from '../../components/navbar/Navbar'
import './about.css' 
import banner from '../../assets/imo10.jpg'

import image4 from '../../assets/header-1.jpg'
import AOS from 'aos';
import 'aos/dist/aos.css';



const About = () => {

  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div className='about'>
    <Navbar  />
    <div className='about-container'>
      <div className='about-header'>
          <div className='about-header-container'>
              <div className='about-image' >
              <div className='about-opacity'></div>
                <img id='aboutss' src={banner} />
              </div>
              <div className='about-title' data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                <h1>Rayyan Bank</h1>
                <p>Unleash Your Financial Potential with Rayyan Bank</p>
              </div>
          </div>
       
      </div>
        <div className='about-content-container'  data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
          <div className='about-content'>
              <h1>About us</h1>
              <p>Rayyan Bank is a non-interest (Islamic) bank licensed by the Central Bank of Nigeria (CBN) in August 2022. As an Islamic retail bank, Rayyan Bank provides a wide range of corporate, private, and investment banking services to individuals and enterprises. Our commitment to interest-free banking drives us to offer Shariah-compliant financial products and services through our extended digital platforms.</p>
          </div>
          </div>
          <div className='statement-container'>
           
            <div className='statement-content-image'>
              <img src={image4} />
            </div>
            <div className='statement-content'>
              <div className='statement-content-vision'>
                <h1>Mission</h1>
                <p>At Rayyan Bank, we are dedicated to stimulating innovation and sustainable financial solutions that promote inclusion and shared prosperity. As real people, we understand the importance of an ethical bank that allows you to stay in control of your finances without compromising your beliefs.</p>
              </div>
              <div className='statement-content-vision'>
                <h1>Vision</h1>
                <p>To be the vangaurd of sharia-compliant digital banking.</p>
              </div>
              <div className='statement-content-vision'>
                <h1>Business Model</h1>
                <p>Rayyan Bank operates as a retail bank, catering to both individual and corporate customers. We offer a comprehensive suite of financial products and services designed to meet the unique needs of our clients. Our business model is built on the principles of Shariah-compliant banking, ensuring that our operations align with ethical standards.</p>
              </div>
              <div className='statement-content-vision'>
                <h1>Core Values</h1>
                <p>Interest free, Transparency, Equity, Customer centric, Innovation and Fairness.</p>
              </div>
            </div>
          </div>
        <Footer />
    </div>
    </div> 
  )
}

export default About
